
.image-liste {
  height: 100%;
  object-fit: cover;
}

.btnreservation {
  right: 0.25em;
  top: 0.25em;
}

.description {
  width: 100%;
}
