.form-check-input {
  transform: scale(1.25);
}

.navbar {
  padding-left: $spacer;
  padding-right: $spacer;
  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-vitrine {
  max-width: 1760px;
  padding-left: $spacer;
  padding-right: $spacer;
  @include media-breakpoint-up(lg) {
    padding-left: $spacer*2;
    padding-right: $spacer*2;
  }
  @include media-breakpoint-up(xl) {
    padding-left: $spacer*4;
    padding-right: $spacer*4;
  }
}

.navbar-vitrine {
  top: 0;
  @include media-breakpoint-up(lg) {
    top: -58px;
  }
}

.vue-grille {
  @import 'Grille';
}

.vue-liste {
  @import 'Liste';
}
