.cursor-pointer {
  cursor: pointer;
}

.vitrine {
  @import 'Vitrine/Vitrine';
}

.dashboard {
  @import 'Dashboard/Dashboard';
}
