.navbar {
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}

.opacity50 {
  opacity: 0.5;
}
